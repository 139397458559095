import { Core } from './core.js'
import $ from 'jquery'
import { _gaq } from './analyticsScript.js'

Core.register("startupModule", function(sandbox) {
  return {
    init: function () {
      
      var startupBanner = $("#startupBanner");
      var startupCountdown = $("#startupCountdown");
      var cameraAlertText = $("#cameraAlertText");
      var startupContainer = $("#startupContainer");
      var welcomeText = $("#welcomeText");
      
      var countdown = {
        id: null,
        time: null
      }
      
      var instructionIndex = 0;
      var instructionList = [
        {time: 10, id: "#cameraAlertText"},
        {time:  6, id: "#bufferItem1"},
        {time:  3, id: "#bufferItem2"},
        {time: -1, id: "#bufferItem3"},
      ];
      
      function onStart() {				
        $("#arrowContainer").load("assets/arrow.svg", function () {
          $(instructionList[instructionIndex].id).css('opacity', '1');
          startupContainer.fadeIn('slow');
          _gaq.push(['_trackEvent', 'Version', '1.0']);
        });
      }
      
      /*var filename = '';
      for (var i = 1; i <= 3; i++) {
        filename = 'assets/face'+i;
        if (i == 2) {
          filename += '-2'; 
        }
        filename += '.svg';
        $("#bufferFace"+i).load(filename, function () {});
      }*/
      
      function hideWelcomeText() {
        welcomeText.fadeOut('slow');
      }

      function startCountdown() {
        $(instructionList[instructionIndex].id).css('display', 'none');
        showNextInstruction();
        startupCountdown.html(countdown.time--);
        startupCountdown.css('display', 'block');
        countdown.id = setInterval(countdownCallback, 1000);
      }

      function countdownCallback() {
        if (countdown.time > 0) {
          startupCountdown.html(countdown.time);
          updateInstruction(countdown.time);
          countdown.time--;
        } else {
          startupCountdown.html(countdown.time);
          startupContainer.fadeOut('slow');
          sandbox.notify({
            type: "startupFinished",
            data: null
          });
          clearInterval(countdown.id);
          countdown.id = null;
        }
      }
      
      function showNextInstruction() {
        instructionIndex++;
        $(instructionList[instructionIndex].id).css('opacity', '1');
      }
      
      function updateInstruction(time) {
        if (instructionIndex < instructionList.length) {
          if (time <= instructionList[instructionIndex].time) {
            showNextInstruction();
          }
        }
      }
      
      this.handleNotification = function (event) {
        if (event.type == "videoLoadEnd") {
          if (event.data.time < 0) {
            cameraAlertText.html(event.data.error);
            cameraAlertText.css('z-index','500');
            startupContainer.fadeIn();
          } else {
            countdown.time = (event.data.time / 1000) + 1;
            hideWelcomeText();
            startCountdown();
          }
        } else if (event.type == "start") {
          onStart();
        }
      };
      
      var registeredEvents = ["videoLoadEnd", "start"];
      sandbox.listen(registeredEvents, this.handleNotification, "startupModule");
    },
    
    destroy: function () {
      // destructor
    }	
  };
});	

import { Core } from './core.js'
import $ from 'jquery'
import { _gaq } from './analyticsScript.js'
import * as Facebook from 'fb-sdk-wrapper';

Core.register("shareModule", function(sandbox) {
  return {
    init: function () {
      
      var domain = 'http://www.doilookweird.com/'; //'http://silent-castle-2646.herokuapp.com/';
      var snapshotBackdrop = $('#snapshotBackdrop');
      var snapshot = document.getElementById('snapshot');
      var snapshotControls = $('#snapshotControls');
      var imgContainer = $('#imgContainer');
      var imgDownloadLink = $('#imgDownloadLink');
      var imgLinkTextField = $('#imgLinkTextField');
      var imgLinkTextContainer = $('#imgLinkTextContainer');
      var closeButton = $('#closeButton');
      var saveButton = $('#saveButton');
      var shareButton = $('#shareButton');
      var shareStatus = $('#shareStatus');
      var loadingSpinner = $('#loadingSpinner');
      var shareFlyout = $('#shareFlyout');
      var lightDismissWindow = $('#lightDismissWindow');
      var copyLinkOption = $('#copyLinkOption');
      var shareTofbOption = $('#shareTofbOption');
      var lastSuccessfulResponse = '';
      var fNewImage = true;
      var imageLink = '';
      var image = {
        base64: '',
        type: ''
      }
      
      imgLinkTextField.on('click', function () {
        this.focus();
          this.select();
      });
      
      loadingSpinner.load('assets/favicon.svg', function () {
        this.children[0].style.top = '-22px';
        this.children[0].style.left = '-21px';
      });
      
      function showStatus(message, fShowSpinner, fPersist) {
        shareStatus.text(message);
        shareStatus.stop();
        shareStatus.css('opacity', 1);
        if (!fPersist) {					
          shareStatus.animate({
            opacity: 0
          }, 5000, null);
        }
        if (fShowSpinner) {
          loadingSpinner.css('display', 'block');
        } else {
          loadingSpinner.css('display', 'none');
        }
      }
      
      function hideStatus () {
        shareStatus.stop();
        shareStatus.css('opacity', 0);
        loadingSpinner.css('display', 'none');
      }
      
      function showShareOptions() {
        shareFlyout.fadeIn(400, 'swing', function () {
          lightDismissWindow.css('display', 'block');
        });
      }
      
      var showLinkCallback = function (response) {
        imgLinkTextField.val(domain+'?id='+response.data.id+'&t='+image.type);
        showStatus('See the link above', false, false);
        imgLinkTextContainer.fadeIn();
      }
      
      copyLinkOption.on('mouseup', function () {
        shareFlyout.hide();
        dismissFlyout();
        if (imgLinkTextContainer.is(':visible')) {
          showStatus('See the link above', false, false);				
        } else {
          uploadToServer(showLinkCallback);
          _gaq.push(['_trackEvent', 'Controls',  'Copy Link '+image.type]);
        }
      });

      var shareTofbCallback = function (response) {
        var link = response.data.link;						
        var parameters = {
          method: 'feed',
          name: (image.type === 'png' ? 'My Snapshot': 'My Animation - Click Me!'),
          picture: link,
          link: domain + '?id='+response.data.id+'&t='+image.type,
          name: 'Do I Look Weird?',
          type: 'photo',
          description: 'DoILookWeird shows me what I looked like up to 8 seconds ago. Weird!'
        }
        
        Facebook.ui(parameters, function(response) {
          if (!response || response.error) {
            showStatus('An error occured. Try again.', false, true);
            shareTofbOption.removeAttr('disabled');
            _gaq.push(['_trackEvent', 'Facebook', 'Error']);
          } else {
            showStatus('Successfully shared on Facebook', false, false);
            _gaq.push(['_trackEvent', 'Facebook', 'Shared '+image.type]);
          }
        });
      }
      
      shareTofbOption.on('mouseup', function () {
        if (shareTofbOption.attr('disabled') != 'disabled') {
          dismissFlyout();
          shareTofbOption.attr('disabled', 'disabled');
          onPostToFacebook();
          _gaq.push(['_trackEvent', 'Controls',  'Share To Facebook '+image.type]);
        }
      });
      
      lightDismissWindow.on('mousedown', function () {
        shareFlyout.hide();
        lightDismissWindow.css('display','none');
        shareButton.removeAttr('disabled');
      }); 
      
      function dismissFlyout () {
        shareFlyout.hide();
        lightDismissWindow.css('display','none');
        shareButton.removeAttr('disabled');
      }
      
      function uploadToServer(callback) {
        //var fNewImage = false;
        if (fNewImage) {
          fNewImage = false;	
          showStatus('Loading image', true, false);				
          var dataStart = ('data:image/'+image.type+';base64,').length;
          var img = image.base64.substring(dataStart);
          
          $.ajax({
            beforeSend: function (req) {
              req.setRequestHeader("Authorization", 'Client-ID '+'a10f81e1653cbe4');
            },
            url: 'https://api.imgur.com/3/image',
            type: 'POST',
            data: {
              type: 'base64',
              image: img
            },
            dataType: 'json',
            success: function(response) {
              lastSuccessfulResponse = response;
              callback(response);
            },
            error: function(error) {
              showStatus('An error occured. Try again.', false, true);
              fNewImage = true;
              shareTofbOption.removeAttr('disabled');
            }
          });
        } else {
          callback(lastSuccessfulResponse);
          //callback({data: {link: 'http://i.imgur.com/hLY9rlX.gif'+'hLY9rlX',id:'hLY9rlX' }});
        }
      }
      
      function onPostToFacebook() {				
        Facebook.getLoginStatus(function(response) {
          if (response.status === 'connected') {
            uploadToServer(shareTofbCallback);
            _gaq.push(['_trackEvent', 'Facebook', 'Connected']);
          } else {						
            Facebook.login(function(response) {
              if (response.authResponse) {
                uploadToServer(shareTofbCallback);
              } else {
                showStatus('Share requires Facebook permission', false, false);
                shareTofbOption.removeAttr('disabled');
              }
            });
            _gaq.push(['_trackEvent', 'Facebook', 'Login Required']);
          }
        });
      }
      
      shareButton.on("mouseup", function (event) {
        if (shareButton.attr('disabled') != 'disabled') {
          hideStatus();
          shareButton.attr('disabled', 'disabled');
          showShareOptions();
        }
      });
            
      saveButton.on("mouseup", function (event) {
        hideStatus();
        var imgDownloadLink = document.getElementById("imgDownloadLink");
        var evt = document.createEvent("MouseEvents"); 
        evt.initMouseEvent("click", true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null); 
        var allowDefault = imgDownloadLink.dispatchEvent(evt);
        _gaq.push(['_trackEvent', 'Controls',  'Saving '+image.type]);
      });

      closeButton.on("mouseup", function (event) {
        hideStatus();
        imgContainer.fadeOut("fast");
        snapshotControls.css('background-color','rgba(0,0,0,0.5)');
      });
      
      function resetOptions () {
        hideStatus();
        imgLinkTextContainer.hide();
        shareTofbOption.removeAttr('disabled');
        fNewImage = true;
      }
      
      this.handleNotification = function (event) {
        if (event.type == "imageLoaded") {
          resetOptions();
          
          snapshotControls.css('background-color','rgba(0,0,0,0)');
          imgContainer.css('display','block');
          
          image = event.data;
          document.getElementById("imgDownloadLink").href = image.base64;
          document.getElementById("imgDownloadLink").download = ((image.type == 'png') ? 'Snapshot' : 'Animation');
        } else if (event.type == "GIFCreationStarted") {
          if (imgContainer.is(':visible')) {
            showStatus('Creating GIF', true, false);
          }
        }
      };
      
      var registeredEvents = ["imageLoaded", "GIFCreationStarted"];
      sandbox.listen(registeredEvents, this.handleNotification, "shareModule");
    },
    
    destroy: function () {
      // destructor
    }
  };
});	

import { Core } from './core.js'
import $ from 'jquery'
import { _gaq } from './analyticsScript.js'

Core.register("webcamModule", function(sandbox) {
  return {
    init: function () {

      var webcamVideo;
      var webcamCanvas;
      var webcamCTX;
    
      // msPerFrame multiplied by the buffer size gives the lag in milliseconds
      var msPerFrame = 100;
      var bufferSize = 80; //80;
      var timeDelay = msPerFrame*bufferSize;
      var framesPerSecond = 1000 / msPerFrame;
      var timeDelayUserAdjusted = 0;
      var GIFBufferSize = (bufferSize/10) + 1;
      var GIFTotalFrames = GIFBufferSize;
      var GIFAvailableFrames = 0;
      var currGIFIndex = 0;
      var fStartAddingGIFFrames = false;
              
      var bufferDelay = new Array(bufferSize);
      for (var i = 0; i < bufferSize; i++) {
        bufferDelay[i] = document.createElement('canvas');
      }
      
      var fWriteRepeat = true;
      var GIFscale = 0.5;
      var bufferDelayGIF = new Array(GIFBufferSize);
      var workerArray = new Array(GIFBufferSize);

      const getPathFromPublic = path => `${process.env.PUBLIC_URL}/${path}`;

      for (var i = 0; i < GIFBufferSize; i++) {
        bufferDelayGIF[i] = document.createElement('canvas');
        const workerPath = getPathFromPublic('js/animWorker.js');
        workerArray[i] = new Worker(workerPath);
      }
      
      var index = 0;
      var intervalId = null;

      var fWindowSizeDirty = true;
      
      function onStart() {
        window.onresize = function(event) {
          fWindowSizeDirty = true;
        }
        
        webcamVideo = document.getElementById('webcamLive');
        navigator.getUserMedia  = navigator.mediaDevices.getUserMedia;
        if (navigator.getUserMedia) {
          var constraints = {video: true, audio: false};
          navigator.mediaDevices.getUserMedia(constraints)
            .then(function(stream) {
              streamSuccess(stream);
            })
            .catch(function(err) {
              streamFailure(err);
            });
        } else {
          sandbox.notify({
            type: "videoLoadEnd",
            data: { time: -1, error: "Your web browser currently doesn't support the functionality in this application. Tested browsers include: Chrome version 24+"}
          });
          _gaq.push(['_trackEvent', 'Webcam', 'Error - Browser Not Supported']);
        }
      }
      
      function streamFailure(e) {
        sandbox.notify({
          type: "videoLoadEnd",
          data: { time: -1, error: "An error occurred when loading the video :("}
        });
        _gaq.push(['_trackEvent', 'Webcam', 'Error - Video Loading Error']);
      }
      
      function streamSuccess(stream) {
        webcamVideo.srcObject = stream;
        webcamVideo.play()
        
        sandbox.notify({
            type: "videoLoadEnd",
            data: { time: timeDelay }
          });
        
        webcamVideo.addEventListener('canplay', function(e) {
          for (var i = 0; i < bufferSize; i++) {
            bufferDelay[i].width = webcamVideo.videoWidth;
            bufferDelay[i].height = webcamVideo.videoHeight;
            
            bufferDelayGIF[i%GIFBufferSize].width = webcamVideo.videoWidth * GIFscale;
            bufferDelayGIF[i%GIFBufferSize].height = webcamVideo.videoHeight * GIFscale;
          }
          
          webcamCanvas = document.getElementById('webcamDelayed');
          webcamCTX = webcamCanvas.getContext('2d');
          
          intervalId = setInterval ( streamCallback, msPerFrame );
          _gaq.push(['_trackEvent', 'Webcam', 'Success']);
        });
      }
      
      var widthScaleFactor, heightScaleFactor, scaleFactor, scaledWidth, scaledHeight, overflowX, overflowY;
      var contextCopy, adjustedIndex, frameIndex;
      var contextGIFCopies = new Array(GIFBufferSize);
      function streamCallback() {
        contextCopy = bufferDelay[index].getContext('2d');
        contextCopy.clearRect(0, 0, webcamVideo.videoWidth, webcamVideo.videoHeight);
        contextCopy.drawImage(webcamVideo, 0, 0);
        
        index++;
        index %= bufferSize;
        
        adjustedIndex = index + timeDelayUserAdjusted;
        adjustedIndex %= bufferSize;
        
        if (fWindowSizeDirty) {
          
          fWindowSizeDirty = false;
          
          // make the canvas as big as the window
          webcamCanvas.width = window.innerWidth;
          webcamCanvas.height = window.innerHeight;

          // calculate how to scale the images										
          widthScaleFactor = window.innerWidth / webcamVideo.videoWidth;
          heightScaleFactor = window.innerHeight / webcamVideo.videoHeight;
          scaleFactor = (widthScaleFactor > heightScaleFactor) ? widthScaleFactor : heightScaleFactor;
          
          scaledWidth = webcamVideo.videoWidth * scaleFactor;
          scaledHeight = webcamVideo.videoHeight * scaleFactor;
          
          overflowX = webcamCanvas.width - scaledWidth;
          overflowY = webcamCanvas.height - scaledHeight;

          overflowX = (overflowX > 0) ? 0 : overflowX;
          overflowY = (overflowY > 0) ? 0 : overflowY;
          
          // reflect the video
          webcamCTX.translate(webcamCanvas.width, 0);
          webcamCTX.scale(-1, 1);
          
        }
        
        if (fWriteRepeat && (index % GIFBufferSize) == 0) {
          frameIndex = index / GIFBufferSize;
          currGIFIndex = frameIndex % GIFBufferSize;
          
          if (GIFAvailableFrames < GIFBufferSize && fStartAddingGIFFrames) {
            GIFAvailableFrames += 1;
          } else if (GIFAvailableFrames == GIFBufferSize) {
            fStartAddingGIFFrames = false;
          }
          
          contextGIFCopies[frameIndex % GIFBufferSize] = bufferDelayGIF[frameIndex % GIFBufferSize].getContext('2d');
          contextGIFCopies[frameIndex % GIFBufferSize].clearRect(0, 0, webcamVideo.videoWidth, webcamVideo.videoHeight);
          contextGIFCopies[frameIndex % GIFBufferSize].drawImage(bufferDelay[adjustedIndex], 0, 0, webcamVideo.videoWidth * GIFscale, webcamVideo.videoHeight * GIFscale);
        }
        webcamCTX.clearRect(0, 0, webcamVideo.videoWidth, webcamVideo.videoHeight)
        
        // draw the image and scale to keep aspect ratio constant
        webcamCTX.drawImage(false ? bufferDelayGIF[index % GIFBufferSize] : bufferDelay[adjustedIndex], 
                  0, 0,
                  webcamVideo.videoWidth, webcamVideo.videoHeight,
                  overflowX / 2 , overflowY / 2,
                  scaledWidth, scaledHeight);
      }

      function createPNG() {
        var type = 'png';
        var dataUrl = bufferDelay[adjustedIndex].toDataURL("image/"+type);
        loadImage(dataUrl, type);
      }
      
      function createGIF() {
        sandbox.notify({
            type: "GIFCreationStarted",
            data: null
          });
            
        var delay = 200;
        var nGIFFrames = (GIFTotalFrames > GIFAvailableFrames) ? GIFAvailableFrames : GIFTotalFrames;
        var animation_parts = new Array(nGIFFrames);
        
        for (var i = 0; i < nGIFFrames; i++) {
          var worker = workerArray[i];
          worker.onmessage = function(e)
          {
            if (e.data["status"] == "framestart") {
              //console.log("Starting processing of frame "+e.data["frame_index"]);
              return;
            } else if (e.data["status"] == "addstart") {
              //console.log("Starting adding of frame "+e.data["frame_index"]);
              return;
            } else if (e.data["status"] == "addend") {
              //console.log("Starting adding of frame "+e.data["frame_index"]);
              return;
            }
            
            var frame_index = e.data["frame_index"];
            var frame_data = e.data["frame_data"];
            animation_parts[frame_index] = frame_data;
            if (e.data["status"] == "frameend") {
              //console.log("Finished processing of frame "+e.data["frame_index"]);
            }
            
            for(var j = 0; j < nGIFFrames; j++)
            {
              if(animation_parts[j] == null)
              {
                return;
              }
            }
            //console.log("Appending Frames");
            var binary_gif = animation_parts.join('');
            var dataUrl = 'data:image/gif;base64,'+window.btoa(binary_gif);
            document.getElementById("snapshot").src = dataUrl
            _gaq.push(['_trackEvent', 'GIF', 'Creation Success']);
            loadImage(dataUrl, 'gif');
          }
         
          var mappedIndex = ((currGIFIndex - nGIFFrames + 1)+GIFBufferSize+i) % GIFBufferSize;
          var imgData = contextGIFCopies[mappedIndex].getImageData(0,0, webcamVideo.videoWidth * GIFscale, webcamVideo.videoHeight * GIFscale).data;
          worker.postMessage({"frame_index": i, "delay": 200, "frame_length":nGIFFrames-1, "height":webcamVideo.videoHeight * GIFscale, "width":webcamVideo.videoWidth * GIFscale, "imageData":imgData});
          fWriteRepeat = true;
        }
      }
      
      function loadImage(dataUrl, type) {
        var imgDiv = document.getElementById("snapshot");
        imgDiv.src = dataUrl;
        imgDiv.onload = function () {
          sandbox.notify({
            type: "imageLoaded",
            data: { base64: dataUrl, type: type }
          });
        }
      }
      
      function onDelayChange(delta) {
        if (timeDelayUserAdjusted === bufferSize - 1) {
          timeDelayUserAdjusted = bufferSize;
        }
        
        if ((delta > 0) && (timeDelayUserAdjusted < bufferSize) ||
          (delta < 0) && (timeDelayUserAdjusted > 0)) {
          timeDelayUserAdjusted += delta*framesPerSecond;
          GIFTotalFrames -= delta;
        }
        
        if (timeDelayUserAdjusted === bufferSize) {
          timeDelayUserAdjusted -= 1;
        }
      }

      this.handleNotification = function (event) {
        if (event.type == "start") {
          onStart();
        } else if (event.type == "startupFinished") {
          fStartAddingGIFFrames = true;
        } else if (event.type == "delayChange") {
          onDelayChange(event.data);
        } else if (event.type == "snapshot") {
          createPNG();
        } else if (event.type == "filmstrip") {
          fWriteRepeat = false;
          createGIF();
        }
      };
      
      var registeredEvents = ["delayChange", "snapshot", "filmstrip", "start", "startupFinished"];
      sandbox.listen(registeredEvents, this.handleNotification, "webcamModule");
    },
    
    destroy: function () {
      // destructor
    }
  };
});	

const Core = (function() {
  var moduleData = {};
  var eventListeners = [];
  
  return {
    id: Math.random(),
    register: function (moduleId, creator) {
      moduleData[moduleId] = {
        creator: creator,
        instance: null,
      };
    },
    
    start: function (moduleId) {
      moduleData[moduleId].instance =
        moduleData[moduleId].creator(Core.sandbox);
      moduleData[moduleId].instance.init();
    },
    
    stop: function(moduleId) {
      var data = moduleData[moduleId];
      if (data.instance) {
        data.instance.destroy();
        data.instance = null;
      }
    },
    
    startAll: function() {
      this.moduleAll(this.start);
    },
    
    stopAll: function () {
      this.moduleAll(this.stop);
    },
    
    moduleAll: function(func) {
      for (var moduleId in moduleData) {
        if (moduleData.hasOwnProperty(moduleId)) {
          func(moduleId);
        }
      }
    },
    
    sandbox: {
      notify: function (triggeredEvent) {
        for (var moduleId in eventListeners) {
          var events = eventListeners[moduleId].events;
          for (var eventListenedFor in events) {
            if (triggeredEvent.type === events[eventListenedFor]) {
              eventListeners[moduleId].callback(triggeredEvent);
            }
          }
        }
      },
      
      listen: function (events, callback, moduleId) {
        eventListeners[moduleId] = {
          events: events,
          callback: callback
        };
      }
    }
  }
}());

export{ Core }

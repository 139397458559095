import { Core } from './core.js'
import $ from 'jquery'
import { _gaq } from './analyticsScript.js'

Core.register("urlModule", function(sandbox) {
  return {
    init: function () {
      var parameters = grabURLParameters();
      var imgId = parameters["id"];
      var type = parameters["t"];
      var urlContainer = $('#urlContainer');
      var icon = $('#iconContainer');
      var tryItButton = $('#tryItButton');
      var image = {
        width: 0,
        height: 0
      }
      
      var fTimedOut = false;
      var imgDiv = document.getElementById('sharedImage');
      var imgDivJ = $('#sharedImage');
      
      if (imgId && type) {
        imgDiv.src = 'http://i.imgur.com/'+imgId+'.'+type;
        
        imgDiv.onload = function () {
          image.width = imgDiv.naturalWidth;
          image.height = imgDiv.naturalHeight;
          onWindowResize();
          window.onresize = onWindowResize;
          icon.load("assets/favicon.svg", function () {
            clearTimeout(timer);
            fTimedOut = false;
            icon.on("mouseup", notifyOfStart);
            tryItButton.on("mouseup", notifyOfStart);
            urlContainer.fadeIn();
            _gaq.push(['_trackEvent', 'Url', 'Image: '+type, imgId]);
          });	
        }
        
        var timer = setTimeout(function () {
          fTimedOut = true;
          notifyOfStart();
        }, 3000);
        
      } else {
        notifyOfStart();
      }
      
      function notifyOfStart () {
        window.onresize = '';
        window.history.pushState({},"Do I Look Weird?","/");
        sandbox.notify({
          type: "start"
        });
        urlContainer.fadeOut(400, function () {
          urlContainer.remove();
        });
      }
      
      function grabURLParameters() {
        var queryTerms = window.location.search.slice(1).split("&");
        var pair, parameters = {};
        for (var i = 0; i < queryTerms.length; i++) {
          pair = queryTerms[i].split("=");
          parameters[pair[0]] = pair[1];
        }
        return parameters;
      }
      
      function onWindowResize(event) {

        // calculate how to scale the image										
        var widthScaleFactor = window.innerWidth / image.width;
        var heightScaleFactor = window.innerHeight / image.height;
        var scaleFactor = (widthScaleFactor > heightScaleFactor) ? widthScaleFactor : heightScaleFactor;
        
        var scaledWidth = image.width * scaleFactor;
        var scaledHeight = image.height * scaleFactor;
        
        var overflowX = window.innerWidth - scaledWidth;
        var overflowY =  window.innerHeight - scaledHeight;
        
        imgDivJ.width(scaledWidth);
        imgDivJ.height(scaledHeight);
        
        imgDivJ.css('margin-left', ((overflowX / 2) + 'px'));
        imgDivJ.css('marginTop', ((overflowY / 2) + 'px'));
      }
    },
    
    destroy: function () {
      // destructor
    }	
  };
});	

import { Core } from './core.js'
import $ from 'jquery'
import { _gaq } from './analyticsScript.js'

global.$ = $;

Core.register("controlsModule", function(sandbox) {
  return {
    init: function () {
      var state = 0;
      var maxState = 8;
      var minState = 0;
      var angle = 300 / maxState;
      var backArrow = null;
      var forwardArrow = null;
      var controlInstructionsX = $('#instructionsCloseX');
      var timeControls = $('#timeControls');
      var time = $('#secondsDisplay');
      var controlsInstruction = $('#controlsInstruction');
      var snapshotControls = $('#snapshotControls');
      var snapshotIcon = $('#snapshotIcon');
      var filmStripIcon = $('#filmStripIcon');
      var snapshotBackdrop = $('#snapshotBackdrop');
      var fbLikeButton = $('#fbLikeButton');
      var animationsPlaying = 0;
      var key = {
        left: 37,
        right: 39
      }

      controlInstructionsX.on("mousedown", function (event) {
        controlsInstruction.hide();
      })

      timeControls.load('assets/logo.svg', function () {
        backArrow = document.getElementById("backArrow");
        backArrow.addEventListener("mousedown", function (event) {
          onDelayChange(-1);
          _gaq.push(['_trackEvent', 'Controls', 'Back Arrow']);
        });

        forwardArrow = document.getElementById("forwardArrow");
        forwardArrow.addEventListener("mousedown", function (event) {
          onDelayChange(1);
          _gaq.push(['_trackEvent', 'Controls', 'Forward Arrow']);
        });

        onDelayChange(0);
      });

      snapshotIcon.load('assets/camera.svg', function () {
        snapshotIcon.on("mousedown", function (event) {
          snapshotBackdrop.css('display', 'block');
          var element = document.getElementById('snapshotBackdrop');
          var newOne = element.cloneNode(true);
          element.parentNode.replaceChild(newOne, element);
          sandbox.notify({
            type: "snapshot",
            data: null
          });
          _gaq.push(['_trackEvent', 'Controls', 'Snapshot Taken']);
        });
      });

      filmStripIcon.load('assets/filmstrip.svg', function () {
        filmStripIcon.on("mousedown", function (event) {
          sandbox.notify({
            type: "filmstrip",
            data: null
          });
          _gaq.push(['_trackEvent', 'Controls', 'Film Taken']);
        });
      });

      function setupKeyboardNavigation () {
        $(document).keydown(function(e) {
          switch (e.keyCode) {
            case key.right:
              onDelayChange(1);
            break;
            case key.left:
              onDelayChange(-1);
            break;
            default:
              break;
          }
        });
      }

      function onDelayChange(delta) {
        if (((state > minState) && (delta < 0)) || 
            ((state < maxState) && (delta > 0)) ||
              (delta == 0)) 
          {
            var clockHandPast = document.getElementById("clockHandPastContainer");
            state += delta;
            switch (state) {
              case maxState:
                backArrow.setAttribute("class", "buttonEnabled");
                forwardArrow.setAttribute("class", "buttonDisabled");
                break;
              case minState:
                backArrow.setAttribute("class", "buttonDisabled");
                forwardArrow.setAttribute("class", "buttonEnabled");
                break;
              default:
                forwardArrow.setAttribute("class", "buttonEnabled");
                backArrow.setAttribute("class", "buttonEnabled");
                break;
            }

            if (delta !== 0) {
              var seconds = maxState - state;
              time.html(seconds + ' second'+((seconds === 1) ? ' ':'s ')+'ago');
              time.stop();
              time.css('opacity', 1);
              time.animate({
                opacity: 0
              }, 1000, null);
            }

            clockHandPast.setAttribute('transform','rotate('+state*angle+', 80.939, 19.167)');
            sandbox.notify({
              type: "delayChange",
              data: delta
            });
          }
      }

      this.handleNotification = function (event) {
        if (event.type == "videoLoadEnd") {
          maxState = event.data.time / 1000;
          timeControls.css('display', 'block');						
        } else if (event.type == "startupFinished") {
          setTimeout(function () {
            timeControls.css('opacity','1');
            setupKeyboardNavigation();
            snapshotControls.fadeIn(500);
            controlsInstruction.fadeIn(500);
            fbLikeButton.fadeIn(500);
          }, 1000);
        }
      };

      var registeredEvents = ["videoLoadEnd", "startupFinished"];
      sandbox.listen(registeredEvents, this.handleNotification, "controlsModule");
    },

    destroy: function () {
      // destructor
    }
  };
});	
